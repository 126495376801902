import { useState, useEffect, useRef, useContext } from "react";
import Box from "@mui/material/Box";
import { useParams, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import "./Scrollbar.css";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import InfoPage from "../components/InfoPage";
import EditorControls from "../components/EditorControls";
import PhotoViewer from "../components/PhotoViewer";
import PhotoCarousel from "../components/PhotoCarousel";
import { SessionContext } from "../context/SessionContext";
import { NavbarContext } from "../context/NavbarContext";
import SnackBar from "../components/SnackBar";
import Button from "@mui/material/Button";
import LogIn from "./LogIn";
import DownloadIcon from "@mui/icons-material/Download";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

const Editor = ({ supabase }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { folderId } = useParams();
  const [predictions, setPredictions] = useState([]);
  const [albumSettings, setAlbumSettings] = useState({});
  const [unsavedPredictions, setUnsavedPredictions] = useState([]);
  const [changes, setChanges] = useState(0);
  const [selectedPhoto, setSelectedPhoto] = useState(0);
  const [links, setLinks] = useState([]);
  const [processStatus, setProcessStatus] = useState(0);
  const carouselRef = useRef(null);
  const [generating, setGenerating] = useState([]);
  const [blobImages, setBlobImages] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [stucked, setStucked] = useState(false);
  const [resuming, setResuming] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [workingMessage, setWorkingMessage] = useState(
    t("editor.workingMessage")
  );
  const [checkStucked, setCheckStucked] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [alreadyChecked, setAlreadyChecked] = useState([]);
  const [lastGenerationTimestamp, setLastGenerationTimestamp] = useState(null);
  const [numAutoResumeAttempts, setNumAutoResumeAttempts] = useState(0);
  const [processingStarted, setProcessingStarted] = useState(false);
  const [manualSelection, setManualSelection] = useState(false);
  const [finalSelectionCompleted, setFinalSelectionCompleted] = useState(false);

  const session = useContext(SessionContext);
  const navbar = useContext(NavbarContext);

  const handleExport = () => {
    navbar.setSelectedNavItem("download");
    navbar.setOpenNavModal(true);
  };

  const promptPurchase = () => {
    navbar.setSelectedNavItem("more-photos-blocked");
    navbar.setOpenNavModal(true);
  };

  const handleSelect = (i, manual = true) => {
    setSelectedPhoto(i);
    carouselRef?.current?.children?.[i]?.scrollIntoView({
      behavior: "smooth",
      inline: "center",
      block: "nearest",
    });
    if (manual) {
      setManualSelection(true);
    }
  };

  const handleUpdatePredictions = async () => {
    try {
      const {
        data: { session: supabaseSession },
      } = await supabase.auth.getSession();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/update-predictions`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${supabaseSession.access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            album_id: folderId,
            predictions: predictions,
          }),
        }
      );
      const json = await response.json();

      const { error } = json;
      if (error) {
        setSnackBarMessage("✖️ " + t("editor.otherError"));
        setOpenSnackBar(true);
      } else {
        setSnackBarMessage("☁️ " + t("editor.saved"));
        setOpenSnackBar(true);
      }
    } catch (error) {
      setSnackBarMessage("⚙️ " + t("editor.serverError"));
      setOpenSnackBar(true);
    }
  };

  const getData = async () => {
    try {
      if (session?.user?.id) {
        const {
          data: { session: supabaseSession },
        } = await supabase.auth.getSession();

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/supabase/albums`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${supabaseSession.access_token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              album_id: folderId,
            }),
          }
        );

        const { data, error } = await response.json();

        if (!error) {
          if (data?.[0]?.created_by === session?.user?.id) {
            setPredictions(data?.[0]?.predictions?.prediction ?? []);
            setAlbumSettings(data?.[0]?.settings);
            setProcessStatus(data?.[0]?.status);
            if (data?.[0]?.status === 4 && !stucked) {
              setStucked(true);
            }
            if (
              data?.[0]?.photos?.length > 0 &&
              data?.[0]?.photos?.length !== links?.length
            ) {
              setLinks(data?.[0]?.photos);
            }
            return {
              links: data?.[0]?.photos,
              predictions: data?.[0]?.predictions?.prediction ?? [],
              status: data?.[0]?.status,
            };
          } else {
            navbar.setIsVisible(true);
            navigate("/folders");
          }
        }
      }
      return { links: null, predictions: null, status: null };
    } catch (e) {
      return { links: null, predictions: null, status: null };
    }
  };

  function startTimeout() {
    const timeoutDuration = 4 * 60 * 1000;
    const startedTimeoutId = setTimeout(() => {
      setWorkingMessage(t("editor.timeoutMessage"));
      if (session?.user?.id) {
        getData();
      }
    }, timeoutDuration);
    setTimeoutId(startedTimeoutId);
  }

  useEffect(() => {
    if (processStatus === 1 && generating?.length === 1) {
      if (!manualSelection) {
        handleSelect(generating[0] - 1, false);
      }
      if (generating[0] - 1 === selectedPhoto) {
        setManualSelection(false);
      }
    }
  }, [processStatus, generating, manualSelection, selectedPhoto]);

  useEffect(() => {
    if (finalSelectionCompleted) return;

    if (processStatus === 2) {
      if (selectedPhoto === links.length - 2) {
        handleSelect(links.length - 1);
      }
      setFinalSelectionCompleted(true);
    }
  }, [processStatus, selectedPhoto, links, finalSelectionCompleted]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (changes < 1) {
        setChanges((prev) => prev + 1);
      } else {
        setChanges(1);
        setSnackBarMessage("🟢 " + t("editor.saving"));
        setOpenSnackBar(true);
        handleUpdatePredictions();
      }
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [unsavedPredictions]);

  useEffect(() => {
    if (session?.user?.id) {
      getData();
    }
  }, [session?.user?.id]);

  useEffect(() => {
    const albumSub = supabase
      .channel("public:album")
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "album",
          filter: `id=eq.${folderId}`,
        },
        (payload) => {
          if (Object.hasOwn(payload?.new, "predictions")) {
            if (payload?.new?.predictions?.prediction) {
              setPredictions((prev) => {
                let newPredictions = [...payload?.new?.predictions?.prediction];
                newPredictions.splice(0, prev.length === 0 ? 0 : prev.length);
                return [...prev, ...newPredictions];
              });
            }
          }
          setAlbumSettings(payload?.new?.settings || albumSettings);
          setProcessStatus(payload?.new?.status || processStatus);
          if (payload?.new?.status === 4 && !stucked) {
            setStucked(true);
          }
          if (
            payload?.new?.photos?.length > 0 &&
            payload?.new?.photos?.length !== links?.length
          ) {
            setLinks(payload?.new?.photos);
          }
          setLastGenerationTimestamp(new Date().toISOString());
          setProcessingStarted(true);
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(albumSub);
    };
  }, [albumSettings, processStatus, stucked, links, folderId]);

  useEffect(() => {
    if (predictions.length < 1 || !predictions) {
      navbar?.setIsVisible(false);
    } else {
      navbar?.setIsVisible(true);
    }
  }, [predictions]);

  useEffect(() => {
    async function downloadAndCreateBlobURLs() {
      let blobArray = [];
      for (let i = 0; i < links?.length; i++) {
        const file = await session.downloadImage(
          session?.user?.id,
          encodeURIComponent(links?.[i]?.path)
        );
        const blobURL = file ? URL.createObjectURL(file) : null;
        blobArray.push(blobURL);
        setBlobImages(blobArray);
      }
    }

    downloadAndCreateBlobURLs();
  }, [links]);

  function convertirFechaAFechaUnix(fechaISO) {
    var fecha = new Date(fechaISO);
    var fechaUnix = Date.UTC(
      fecha.getUTCFullYear(),
      fecha.getUTCMonth(),
      fecha.getUTCDate(),
      fecha.getUTCHours(),
      fecha.getUTCMinutes(),
      fecha.getUTCSeconds(),
      fecha.getUTCMilliseconds()
    );
    return fechaUnix;
  }

  function obtenerDiferencia(timestampUnixMilisegundos) {
    var ahora = new Date().getTime(); // timestamp actual en milisegundos UTC
    var diferencia = ahora - timestampUnixMilisegundos;
    return diferencia;
  }

  const handlePauseGenerating = async () => {
    try {
      if (stucked || predictions.length >= links.length) {
        setSnackBarMessage("⚠️ " + t("editor.paused"));
        setOpenSnackBar(true);
      } else {
        const {
          data: { session: supabaseSession },
        } = await supabase.auth.getSession();

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/supabase/pause-album`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${supabaseSession.access_token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              album_id: folderId,
            }),
          }
        );

        const { error } = await response.json();

        if (!error) {
          setStucked(true);
        } else {
          setSnackBarMessage("⚠️ " + error);
          setOpenSnackBar(true);
        }
      }
    } catch (error) {
      setSnackBarMessage("⚙️ " + t("editor.serverError"));
      setOpenSnackBar(true);
    }
  };

  const handleResumeGenerating = async () => {
    await new Promise((r) => setTimeout(r, 1500));
    const { links: updatedLinks, predictions: updatedPredictions } =
      await getData();
    if (
      updatedPredictions &&
      updatedLinks &&
      updatedPredictions?.length >= updatedLinks?.length
    ) {
      const {
        data: { session: supabaseSession },
      } = await supabase.auth.getSession();

      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/supabase/resume-album`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${supabaseSession.access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            album_id: folderId,
          }),
        }
      );

      setSnackBarMessage("⚠️ " + t("editor.completed"));
      setOpenSnackBar(true);
    } else {
      setResuming(true);
      try {
        const {
          data: { session: supabaseSession },
        } = await supabase.auth.getSession();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/resume-generating`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${supabaseSession.access_token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_data: {
                album_id: folderId,
              },
              photo: links?.slice((links?.length - predictions?.length) * -1),
            }),
          }
        );
        const json = await response.json();

        const { error } = json;
        if (!error) {
          setResuming(false);
          setStucked(false);
        } else {
          setSnackBarMessage("⚠️ " + error);
          setOpenSnackBar(true);
          setResuming(false);
        }
      } catch (error) {
        setSnackBarMessage("⚙️ Server error");
        setOpenSnackBar(true);
        setResuming(false);
      }
    }
  };

  useEffect(() => {
    if (processStatus !== 0 && predictions?.length > 0) {
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null);
      }
    } else {
      if (!timeoutId && session?.user?.id) {
        startTimeout();
      }
    }
  }, [processStatus, predictions, session?.user?.id, timeoutId]);

  useEffect(() => {
    if (predictions?.length === links?.length && processStatus === 2) {
      if (intervalId) {
        clearInterval(intervalId) && setIntervalId(null);
      }
    }

    if (
      predictions?.length < links?.length &&
      processStatus !== 2 &&
      processStatus !== 4
    ) {
      if (checkStucked) {
        if (!lastGenerationTimestamp) {
          setLastGenerationTimestamp(new Date().toISOString());
        }
        const timeSinceLastGeneration = obtenerDiferencia(
          convertirFechaAFechaUnix(lastGenerationTimestamp)
        );
        if (
          timeSinceLastGeneration >= 3 * 60 * 1000 &&
          !alreadyChecked.includes(lastGenerationTimestamp)
        ) {
          if (session?.user?.id) {
            getData().then((data) => {
              if (
                data?.predictions &&
                data?.links &&
                data?.predictions?.length < data?.links?.length &&
                data?.status === 1 &&
                !resuming &&
                numAutoResumeAttempts < 5 &&
                processingStarted
              ) {
                setNumAutoResumeAttempts((prev) => prev + 1);
                handleResumeGenerating();
              }
            });
          }
          setAlreadyChecked((prev) => [...prev, lastGenerationTimestamp]);
        }
        setCheckStucked(false);
      }
    }
  }, [
    session?.user?.id,
    predictions,
    links,
    processStatus,
    checkStucked,
    alreadyChecked,
    intervalId,
    lastGenerationTimestamp,
    resuming,
    numAutoResumeAttempts,
    processingStarted,
  ]);

  useEffect(() => {
    if (!intervalId) {
      const interval = setInterval(() => {
        setCheckStucked(true);
      }, 1000);
      setIntervalId(interval);
    }
    return () => clearInterval(intervalId) && setIntervalId(null);
  }, []);

  return session?.user ? (
    <Box sx={{ overflowY: "hidden", overflowX: "hidden" }}>
      {processStatus !== 0 && predictions?.length > 0 ? (
        <Grid
          container
          spacing={2}
          sx={{
            position: "relative",
            mt: 10,
            padding: { xs: "0 2rem", md: "0 5rem" },
            backgroundColor: "#000",
            alignItems: "flex-start",
            justify: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              height: "4rem",
              bgcolor: "#121216",
              borderRadius: "1rem",
              marginLeft: "1rem",
              marginTop: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <Typography
                variant="body1"
                color="initial"
                sx={{
                  color: "#ffffff",
                  fontWeight: "bold",
                  marginLeft: "1rem",
                }}
              >
                {albumSettings?.name || folderId || ""}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                endIcon={<DownloadIcon />}
                onClick={session?.enableExport ? handleExport : promptPurchase}
                sx={{
                  marginLeft: "auto",
                  marginRight: "1rem",
                  fontWeight: "bold",
                  fontSize: {
                    xs: "1rem",
                    sm: "1.2rem",
                  },
                  paddingLeft: {
                    xs: "2rem",
                    sm: "1rem",
                  },
                  paddingRight: {
                    xs: "2rem",
                    sm: "1rem",
                  },
                }}
              >
                {t("editor.export")}
              </Button>
            </Box>
          </Box>
          <Grid
            item
            xs={12}
            md={7}
            lg={4}
            xl={3}
            order={{ xs: 2, md: 1, lg: 1 }}
            sx={{
              position: { xs: "relative", md: "sticky" },
            }}
          >
            <SnackBar
              message={snackBarMessage}
              openSnackBar={openSnackBar}
              setOpenSnackBar={setOpenSnackBar}
            />
            <EditorControls
              supabase={supabase}
              links={links}
              selectedPhoto={selectedPhoto}
              handleSelect={handleSelect}
              predictions={predictions}
              setPredictions={setPredictions}
              albumSettings={albumSettings}
              generating={generating}
              setGenerating={setGenerating}
              setUnsavedPredictions={setUnsavedPredictions}
              session={session}
              albumId={folderId}
              setSnackBarMessage={setSnackBarMessage}
              setOpenSnackBar={setOpenSnackBar}
              stucked={stucked}
              handleResumeGenerating={handleResumeGenerating}
              handlePauseGenerating={handlePauseGenerating}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            lg={8}
            xl={9}
            align="center"
            order={{ xs: 1, md: 2, lg: 2 }}
            sx={{
              height: "100%",
              maxWidth: "100%",
            }}
          >
            <Box
              sx={{
                width: { sx: "auto", xl: "80%" },
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <PhotoViewer
                selectedPhoto={selectedPhoto}
                blobImages={blobImages}
                name={links?.[selectedPhoto]?.name ?? ""}
              />
              <PhotoCarousel
                links={links}
                selectedPhoto={selectedPhoto}
                handleSelect={handleSelect}
                predictions={predictions}
                carouselRef={carouselRef}
                generating={generating}
                blobImages={blobImages}
              />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <InfoPage
          type="working"
          title={t("editor.getReady")}
          message={workingMessage}
          icon={AutoFixHighIcon}
          image={blobImages?.[0]}
        />
      )}
    </Box>
  ) : (
    <LogIn customPath={`/result/${folderId}`} />
  );
};

export default Editor;
