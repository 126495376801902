import { createContext, useState, useEffect, useCallback } from "react";
import { supabase } from "../lib/helper/supabaseClient";
import { useNavigate } from "react-router-dom";
import { blockedTerms } from "../blockedTerms";

const countryCodesEU = [
  "de",
  "fr",
  "it",
  "es",
  "pt",
  "nl",
  "pl",
  "at",
  "be",
  "cy",
  "ee",
  "fi",
  "gr",
  "ie",
  "lv",
  "lt",
  "lu",
  "mt",
  "sk",
  "si",
  "sm",
  "hr",
  "va",
  "mc",
  "ad",
  "li",
  "ch",
  "no",
  "se",
  "dk",
  "is",
  "gb",
  "uk",
  "bg",
  "cz",
  "hu",
  "ro",
  "rs",
  "me",
  "al",
  "ba",
  "mk",
  "md",
  "ua",
  "by",
  "ru",
];

export const SessionContext = createContext({});

const SessionContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [stashedUser, setStashedUser] = useState(null);
  const [userChecked, setUserChecked] = useState(false);
  const [bundleCreditsUsed, setBundleCreditsUsed] = useState(undefined);
  const [bundleCreditsAllowed, setBundleCreditsAllowed] = useState(undefined);
  const [bundleCreditsAllowedTotal, setBundleCreditsAllowedTotal] =
    useState(undefined);
  const [showPopup, setShowPopup] = useState(false);
  const [openWindow, setOpenWindow] = useState(null);
  const [currentCurrency, setCurrentCurrency] = useState("USD");
  const [enableExport, setEnableExport] = useState(false);

  useEffect(() => {
    if (bundleCreditsAllowedTotal > 3) {
      setEnableExport(true);
    }
  }, [bundleCreditsAllowedTotal]);

  const checkUser = useCallback(
    async (customPath = undefined) => {
      try {
        const currSession = await supabase?.auth?.getSession();
        const currUser = currSession?.data?.session?.user;

        if (sessionStorage.getItem("active")) {
          if (currUser) {
            if (stashedUser) {
              navigate(customPath || "/upload", {
                state: { closeDrawer: true },
              });
            }
          }
          setStashedUser(null);
          setUser(currUser ?? null);
        } else {
          setStashedUser(currUser ?? null);
          setUser(null);
        }
      } catch (e) {}

      setUserChecked(true);
    },
    [stashedUser, navigate]
  );

  const login = async (providerName) => {
    sessionStorage.setItem("active", true);
    supabase.auth.signInWithOAuth({
      provider: providerName,
    });
  };

  const loginWithEmail = async (email, password, captchaToken) => {
    const { error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
      options: { captchaToken },
    });
    if (!error) {
      sessionStorage.setItem("active", true);
      checkUser();
      if (!stashedUser) {
        navigate("/upload", { state: { closeDrawer: true } });
      }
    }
    return error;
  };

  const signUpWithEmail = async (email, password, captchaToken) => {
    const { data, error } = await supabase.auth.signUp({
      email: email,
      password: password,
      options: { captchaToken },
    });
    if (
      data?.user &&
      data?.user?.identities &&
      data?.user?.identities?.length === 0
    ) {
      return {
        name: "EmailInUseError",
        message: "User already exists",
      };
    }
    if (!error) {
      sessionStorage.setItem("active", true);
    }

    return error;
  };

  const forgotPassword = async (email, captchaToken) => {
    const { error } = await supabase.auth.signInWithOtp({
      email: email,
      options: { captchaToken },
    });
    if (!error) {
      localStorage.setItem("forgotPassword", true);
    }
    return error;
  };

  const changePassword = async (email, password) => {
    const { error } = await supabase.auth.updateUser({
      email: email,
      password: password,
    });
    return error;
  };

  const logout = async () => {
    await supabase.auth.signOut();
    setShowPopup(false);
  };

  const downloadImage = async (id, path) => {
    const currentTime = Date.now();
    const imageDownloadsTimestamp = localStorage.getItem("uploadReset");
    const imageDownloads =
      parseInt(localStorage.getItem("titlesAndDescriptions")) || 0;
    const timeElapsed = currentTime - imageDownloadsTimestamp;

    if (timeElapsed > 60000) {
      localStorage.setItem("titlesAndDescriptions", 0);
      localStorage.setItem("uploadReset", currentTime);
    }

    if (imageDownloads >= 1600) {
      return null;
    }

    const { data, error } = await supabase.storage
      .from("images")
      .download(`${id}/${path}`);
    if (error) {
      return null;
    }

    localStorage.setItem("titlesAndDescriptions", imageDownloads + 1);
    return data;
  };

  const handlePayment = async (
    price_id,
    credits_count,
    multiple,
    coupon_id = undefined
  ) => {
    try {
      if (!user && !stashedUser) {
        throw new Error("User not found");
      }

      if (
        !price_id ||
        !credits_count ||
        !multiple ||
        credits_count > 100000 ||
        multiple > 99
      ) {
        throw new Error("Invalid parameters");
      }

      const {
        data: { session: supabaseSession },
      } = await supabase.auth.getSession();

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/checkout-session`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${supabaseSession.access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customerEmail: user?.email || stashedUser?.email,
            priceId: price_id,
            quantity: multiple,
            creditAmount: credits_count,
            cancelPage: window.location.pathname,
            couponId: coupon_id,
            rewardfulId: window?.Rewardful?.referral || undefined,
          }),
        }
      );

      const { data, error } = await response.json();

      if (!error && data?.url) {
        window.location.href = data.url;
      }
    } catch (e) {}
  };

  const loadUserFromStash = async (customPath = undefined) => {
    sessionStorage.setItem("active", true);
    checkUser(customPath);
  };

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event) => {
      switch (event) {
        case "SIGNED_OUT":
          setUser(null);
          navigate("/", { state: { closeDrawer: true } });
          break;
        default:
          break;
      }
    });

    checkUser();

    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const getBundleCredits = async () => {
      try {
        const {
          data: { session: supabaseSession },
        } = await supabase.auth.getSession();

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/supabase/bundles`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${supabaseSession.access_token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const { data, error } = await response.json();

        if (!error) {
          let bundleUsed = 0;
          let bundleAllowed = 0;
          let bundleAllowedTotal = 0;
          if (data?.length !== 0) {
            for (let i = 0; i < data?.length; i++) {
              if (data[i]?.active) {
                bundleUsed += data[i]?.amount_used;
                bundleAllowed += data[i]?.amount_allowed;
              }
              bundleAllowedTotal += data[i]?.amount_allowed;
            }
          }
          setBundleCreditsUsed(bundleUsed);
          setBundleCreditsAllowed(bundleAllowed);
          setBundleCreditsAllowedTotal(bundleAllowedTotal);
        }
      } catch (e) {}
    };

    const checkCredits = async () => {
      if (user?.id) {
        await getBundleCredits();
      }
    };

    if (user) {
      checkCredits();
    }
  }, [user]);

  useEffect(() => {
    const checkPopup = async () => {
      if (
        bundleCreditsAllowed !== undefined &&
        bundleCreditsUsed !== undefined
      ) {
        if (
          bundleCreditsAllowed - bundleCreditsUsed <= 0 &&
          window.location.pathname !== "/thanks"
        ) {
          setShowPopup(true);
        } else {
          setShowPopup(false);
        }
      }
    };
    if (
      user &&
      bundleCreditsAllowed !== undefined &&
      bundleCreditsUsed !== undefined
    ) {
      checkPopup();
    }
  }, [user, bundleCreditsAllowed, bundleCreditsUsed]);

  useEffect(() => {
    if (user !== null) {
      if (localStorage.getItem("goToCheckout")) {
        const { price_id, credits_count, multiple, coupon_id } = JSON.parse(
          localStorage.getItem("goToCheckout")
        );
        handlePayment(price_id, credits_count, multiple, coupon_id);
      } else if (localStorage.getItem("openCheckout")) {
        setOpenWindow("more-photos");
      } else if (localStorage.getItem("forgotPassword")) {
        setOpenWindow("my-account");
      }
      localStorage.removeItem("goToCheckout");
      localStorage.removeItem("openCheckout");
      localStorage.removeItem("forgotPassword");
    }
  }, [user]);

  useEffect(() => {
    if (
      user?.email &&
      blockedTerms.some((term) => user?.email?.split("@")?.[1]?.includes(term))
    ) {
      logout();
    }
  }, [user]);

  useEffect(() => {
    const currency = localStorage.getItem("currency");
    if (currency) {
      setCurrentCurrency(currency);
    } else {
      const browserLanguage = navigator.language || navigator.userLanguage;
      if (
        browserLanguage &&
        countryCodesEU.some((code) => browserLanguage.startsWith(code))
      ) {
        setCurrentCurrency("EUR");
      }
    }
  }, []);

  return (
    <SessionContext.Provider
      value={{
        login,
        loginWithEmail,
        signUpWithEmail,
        forgotPassword,
        changePassword,
        logout,
        user,
        downloadImage,
        bundleCreditsAllowed,
        bundleCreditsUsed,
        setBundleCreditsUsed,
        showPopup,
        openWindow,
        setOpenWindow,
        stashedUser,
        loadUserFromStash,
        currentCurrency,
        setCurrentCurrency,
        handlePayment,
        enableExport,
        bundleCreditsAllowedTotal,
        userChecked,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionContextProvider;
