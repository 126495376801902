import { supabase } from "./lib/helper/supabaseClient";
import ReactDOM from "react-dom/client";
import "./index.css";
import Home from "./pages/Home";
import ToolEcomm from "./pages/ToolEcomm";
import ToolMetadata from "./pages/ToolMetadata";
import ToolVector from "./pages/ToolVector";
import ToolVideo from "./pages/ToolVideo";
import StockPage from "./pages/StockPage";
import Editor from "./pages/Editor";
import Upload from "./pages/Upload";
import Thanks from "./pages/Thanks";
import Affiliates from "./pages/Affiliates";
import ConfigAPI from "./pages/ConfigAPI";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import SessionContextProvider from "./context/SessionContext";
import NavbarContextProvider from "./context/NavbarContext";
import AppLayout from "./Layout/AppLayout";
import NotFound from "./pages/NotFound";
import IframePage from "./pages/IframePage";
import Albums from "./pages/Albums";
import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import "./i18n";

function App() {
  const { t } = useTranslation();
  const theme = createTheme({
    shape: {
      borderRadius: 20,
    },
    palette: {
      mode: "dark",
      primary: {
        main: "#1a72f5",
      },
      secondary: {
        main: "#3ad0ff",
      },
      info: {
        main: "#ffffff",
      },
    },
    typography: {
      fontFamily: "'EuclidCircularB', sans-serif",
      button: {
        textTransform: "none",
      },
    },
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            color: "primary",
            background: "black",
            boxShadow: "0px 0px 100px rgba(255,58,92,0.3)",
          },
        },
      },
    },
  });

  return (
    <Fragment>
      <Helmet>
        <title>ShopTag.ai - {t("landingHelmet.headingSubtext")}</title>

        {/* Primary Meta Tags */}
        <meta
          name="title"
          content={"ShopTag.ai - " + t("landingHelmet.headingSubtext")}
        />
        <meta
          name="description"
          content={t("landingHelmet.headingDescription")}
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.shoptag.ai/" />
        <meta
          property="og:title"
          content={"ShopTag.ai - " + t("landingHelmet.headingSubtext")}
        />
        <meta
          property="og:description"
          content={t("landingHelmet.headingDescription")}
        />
        <meta
          property="og:image"
          content="https://app.shoptag.ai/storage/v1/object/public/assets/thumbnail.jpg"
        />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.shoptag.ai/" />
        <meta
          property="twitter:title"
          content={"ShopTag.ai - " + t("landingHelmet.headingSubtext")}
        />
        <meta
          property="twitter:description"
          content={t("landingHelmet.headingDescription")}
        />
        <meta
          property="twitter:image"
          content="https://app.shoptag.ai/storage/v1/object/public/assets/thumbnail.jpg"
        />

        {/* Icons */}
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="https://app.shoptag.ai/storage/v1/object/public/assets/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://app.shoptag.ai/storage/v1/object/public/assets/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="https://app.shoptag.ai/storage/v1/object/public/assets/favicon-16x16.png"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <Router>
          <SessionContextProvider>
            <NavbarContextProvider>
              <Routes>
                <Route
                  path="/:section?"
                  element={
                    <AppLayout supabase={supabase}>
                      <Home supabase={supabase} />
                    </AppLayout>
                  }
                />
                <Route
                  path="/ecommerce-ai-image-search"
                  element={
                    <AppLayout supabase={supabase}>
                      <ToolEcomm />
                    </AppLayout>
                  }
                />
                <Route
                  path="/ai-shopify-product-listings"
                  element={
                    <AppLayout supabase={supabase}>
                      <ToolMetadata />
                    </AppLayout>
                  }
                />
                <Route
                  path="/ai-woocommerce-product-listings"
                  element={
                    <AppLayout supabase={supabase}>
                      <ToolVector />
                    </AppLayout>
                  }
                />
                <Route
                  path="/bigcommerce-ai-product-listings"
                  element={
                    <AppLayout supabase={supabase}>
                      <ToolVideo />
                    </AppLayout>
                  }
                />
                <Route
                  path="/stock-photo-keywording"
                  element={
                    <AppLayout supabase={supabase}>
                      <StockPage />
                    </AppLayout>
                  }
                />
                <Route
                  path="/result/:folderId"
                  element={
                    <AppLayout supabase={supabase}>
                      <Editor supabase={supabase} />
                    </AppLayout>
                  }
                />
                <Route
                  path="/folders"
                  element={
                    <AppLayout supabase={supabase}>
                      <Albums supabase={supabase} />
                    </AppLayout>
                  }
                />
                <Route
                  path="/upload/:modal?"
                  element={
                    <AppLayout supabase={supabase}>
                      <Upload supabase={supabase} />
                    </AppLayout>
                  }
                />
                <Route
                  path="/thanks"
                  element={
                    <AppLayout supabase={supabase}>
                      <Thanks />
                    </AppLayout>
                  }
                />
                <Route
                  path="/affiliates"
                  element={
                    <AppLayout supabase={supabase}>
                      <Affiliates />
                    </AppLayout>
                  }
                />
                <Route
                  path="/api"
                  element={
                    <AppLayout supabase={supabase}>
                      <ConfigAPI supabase={supabase} />
                    </AppLayout>
                  }
                />
                <Route
                  path="/tos"
                  element={
                    <AppLayout supabase={supabase}>
                      <IframePage
                        url={"https://app.getterms.io/view/EsB3a/tos/en-us"}
                        title={"ShopTag.ai Terms of Service"}
                      />
                    </AppLayout>
                  }
                />
                <Route
                  path="/privacy"
                  element={
                    <AppLayout supabase={supabase}>
                      <IframePage
                        url={"https://app.getterms.io/view/EsB3a/privacy/en-us"}
                        title={"ShopTag.ai Privacy Policy"}
                      />
                    </AppLayout>
                  }
                />
                <Route
                  path="/aup"
                  element={
                    <AppLayout supabase={supabase}>
                      <IframePage
                        url={"https://app.getterms.io/view/EsB3a/aup/en-us"}
                        title={"ShopTag.ai Acceptable Use Policy"}
                      />
                    </AppLayout>
                  }
                />
                <Route
                  path="/refund-policy"
                  element={
                    <AppLayout supabase={supabase}>
                      <IframePage
                        url={"https://app.getterms.io/view/EsB3a/return/en-us"}
                        title={"ShopTag.ai Refund Policy"}
                      />
                    </AppLayout>
                  }
                />
                <Route
                  path="*"
                  element={
                    <AppLayout supabase={supabase}>
                      <NotFound />
                    </AppLayout>
                  }
                />
              </Routes>
            </NavbarContextProvider>
          </SessionContextProvider>
        </Router>
      </ThemeProvider>
    </Fragment>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
