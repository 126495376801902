import { Fragment, useContext } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import BundleItem from "./bundleItem";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import CurrencySelect from "./CurrencySelect";
import { SessionContext } from "../context/SessionContext";

export default function PricingContainer({ landingPage = true }) {
  const { t } = useTranslation();
  const session = useContext(SessionContext);

  const discount =
    Number(process.env.REACT_APP_STRIPE_DEFAULT_PROMO_PERCENT) / 100;

  const smallCreditCount = 200;
  const smallPriceEUR = 8;
  const smallPriceUSD = 10;

  const mediumCreditCount = 2000;
  const mediumPriceEUR = 46;
  const mediumPriceUSD = 52;

  const largeCreditCount = 20000;
  const largePriceEUR = 224;
  const largePriceUSD = 248;

  const bundles = [
    {
      title:
        "🚲 " +
        t("landing.smallUploadCredits", {
          amount: smallCreditCount.toLocaleString(),
        }),
      count: smallCreditCount,
      price:
        session?.currentCurrency === "EUR"
          ? smallPriceEUR * discount
          : smallPriceUSD * discount,
      old_price:
        session?.currentCurrency === "EUR" ? smallPriceEUR : smallPriceUSD,
      price_id:
        session?.currentCurrency === "EUR"
          ? process.env.REACT_APP_200_PRODUCT_EUR
          : process.env.REACT_APP_200_PRODUCT_USD,
      coupon_id: process.env.REACT_APP_STRIPE_DEFAULT_PROMO,
      subheader: t("landing.starter"),
      description: [
        t("landing.eCommIntegration"),
        t("landing.apiAccess"),
        t("landing.neverExpires"),
      ],
      buttonText: t("landing.buyNow"),
      highlighted: false,
      action: "login",
    },
    {
      title:
        "🏎️ " +
        t("landing.mediumUploadCredits", {
          amount: mediumCreditCount.toLocaleString(),
        }),
      count: mediumCreditCount,
      price:
        session?.currentCurrency === "EUR"
          ? mediumPriceEUR * discount
          : mediumPriceUSD * discount,
      old_price:
        session?.currentCurrency === "EUR" ? mediumPriceEUR : mediumPriceUSD,
      price_id:
        session?.currentCurrency === "EUR"
          ? process.env.REACT_APP_2000_PRODUCT_EUR
          : process.env.REACT_APP_2000_PRODUCT_USD,
      coupon_id: process.env.REACT_APP_STRIPE_DEFAULT_PROMO,
      subheader: t("landing.popular"),
      description: [
        t("landing.eCommIntegration"),
        t("landing.apiAccess"),
        t("landing.neverExpires"),
      ],
      buttonText: t("landing.buyNow"),
      highlighted: true,
      action: "login",
    },
    {
      title:
        "🚀 " +
        t("landing.largeUploadCredits", {
          amount: largeCreditCount.toLocaleString(),
        }),
      count: largeCreditCount,
      price:
        session?.currentCurrency === "EUR"
          ? largePriceEUR * discount
          : largePriceUSD * discount,
      old_price:
        session?.currentCurrency === "EUR" ? largePriceEUR : largePriceUSD,
      price_id:
        session?.currentCurrency === "EUR"
          ? process.env.REACT_APP_20000_PRODUCT_EUR
          : process.env.REACT_APP_20000_PRODUCT_USD,
      coupon_id: process.env.REACT_APP_STRIPE_DEFAULT_PROMO,
      subheader: t("landing.power"),
      description: [
        t("landing.eCommIntegration"),
        t("landing.apiAccess"),
        t("landing.neverExpires"),
      ],
      buttonText: t("landing.buyNow"),
      highlighted: false,
      action: "login",
    },
  ];

  return (
    <Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ pb: 4 }}
        id="packages"
      >
        {landingPage && (
          <>
            <Typography
              component="h1"
              variant="h2"
              align="center"
              gutterBottom
              color={"black"}
              fontWeight={"bold"}
            >
              {t("landing.pricing")}
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                align="center"
                color="black"
                component="p"
                sx={{ fontWeight: "bold", fontSize: "1.2rem", width: "95%" }}
              >
                {t("landing.noSubscription")}
              </Typography>
            </Box>
          </>
        )}
      </Container>
      <Container
        maxWidth="lg"
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid container spacing={3} alignItems="center">
          {bundles.map((bundleItem) => (
            <BundleItem
              title={bundleItem.title}
              credits_count={bundleItem.count}
              subheader={bundleItem.subheader}
              price={bundleItem.price}
              old_price={bundleItem.old_price}
              price_id={bundleItem.price_id}
              coupon_id={bundleItem.coupon_id}
              description={bundleItem.description}
              buttonText={bundleItem.buttonText}
              highlighted={bundleItem.highlighted}
              action={bundleItem.action}
              key={bundleItem.count}
            />
          ))}
        </Grid>

        <CurrencySelect color="#3535ff" />
      </Container>
    </Fragment>
  );
}
