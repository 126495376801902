import React, { useState, useEffect, Fragment } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AlbumsFilter from "../components/AlbumsFilter";
import AlbumRow from "../components/AlbumRow";
import Link from "@mui/material/Link";
import Checkbox from "@mui/material/Checkbox";
import { t } from "i18next";
import { ClipLoader } from "react-spinners";
import Pagination from "@mui/material/Pagination";

const AlbumsTable = ({
  session,
  filteredUserAlbums,
  setFilteredUserAlbums,
  userAlbums,
  navigate,
  actions,
  albumsLoading,
  pagesLoaded,
  setPagesLoaded,
  blobImages,
  itemsPerPage = 20,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [changes, setChanges] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(
    (currentPage - 1) * itemsPerPage
  );
  const [endIndex, setEndIndex] = useState(startIndex + itemsPerPage);
  const [selectedAlbums, setSelectedAlbums] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showAlbumId, setShowAlbumId] = useState(false);

  const loadAllPages = () => {
    setPagesLoaded(9999);
  };

  useEffect(() => {
    if (pagesLoaded === currentPage) {
      setPagesLoaded((prev) => prev + 5);
    }
  }, [currentPage, pagesLoaded, setPagesLoaded]);

  useEffect(() => {
    const showAlbumId = localStorage.getItem("showAlbumId");
    if (showAlbumId) {
      setShowAlbumId(showAlbumId === "show");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("showAlbumId", showAlbumId ? "show" : "hide");
  }, [showAlbumId]);

  useEffect(() => {
    setSelectAll(false);
    setSelectedAlbums([]);
  }, [currentPage]);

  const handleChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    setSearchTerm(value);
    if (value === "") {
      const timerId = setTimeout(() => {
        setFilteredUserAlbums(userAlbums);
      }, 500); // tiempo de espera de 500 ms antes de actualizar debouncedInputValue

      return () => {
        clearTimeout(timerId);
      };
    }
  };

  const filtrarArray = (array, filtro, contextLength = 15) => {
    return array.filter((obj) => {
      const { settings, predictions, photos } = obj;
      const nameMatch = settings?.name
        ?.toLowerCase()
        ?.includes(filtro.toLowerCase());
      const dataMatch =
        predictions?.prediction?.some((pred) => {
          return pred?.data?.some((innerData) => {
            if (typeof innerData === "string") {
              const lowerCasedInnerData = innerData?.toLowerCase();
              const lowerCasedFiltro = filtro.toLowerCase();
              const index = lowerCasedInnerData.indexOf(lowerCasedFiltro);
              if (index !== -1) {
                let startIndex = Math.max(0, index - contextLength);
                let endIndex = Math.min(
                  innerData.length,
                  index + filtro.length + contextLength
                );
                let matchedFragment = innerData.substring(startIndex, endIndex);
                obj.coincidence = matchedFragment;
                return true;
              }
              return false;
            } else if (Array.isArray(innerData)) {
              const matchingItem = innerData.find((item) =>
                item?.toLowerCase()?.includes(filtro.toLowerCase())
              );
              if (matchingItem) {
                const lowerCasedItem = matchingItem.toLowerCase();
                const lowerCasedFiltro = filtro.toLowerCase();
                const index = lowerCasedItem.indexOf(lowerCasedFiltro);
                if (index !== -1) {
                  let startIndex = Math.max(0, index - contextLength);
                  let endIndex = Math.min(
                    matchingItem.length,
                    index + filtro.length + contextLength
                  );
                  let matchedFragment = matchingItem.substring(
                    startIndex,
                    endIndex
                  );
                  obj.coincidence = "..." + matchedFragment + "...";
                  return true;
                }
              }
              return false;
            }
            return false;
          });
        }) || false;

      // Check for name matches in photos
      const nameMatchInPhotos = photos?.some((photo) => {
        const photoNameMatch = photo?.name
          ?.toLowerCase()
          ?.includes(filtro.toLowerCase());
        if (photoNameMatch) {
          const lowerCasedPhotoName = photo.name.toLowerCase();
          const lowerCasedFiltro = filtro.toLowerCase();
          const index = lowerCasedPhotoName.indexOf(lowerCasedFiltro);
          if (index !== -1) {
            let startIndex = Math.max(0, index - contextLength);
            let endIndex = Math.min(
              photo.name.length,
              index + filtro.length + contextLength
            );
            let matchedFragment = photo.name.substring(startIndex, endIndex);
            obj.coincidence = "..." + matchedFragment + "...";
          }
        }
        return photoNameMatch;
      });

      return nameMatch || dataMatch || nameMatchInPhotos;
    });
  };

  const handlePageChange = (e, newPage) => {
    setCurrentPage(newPage);
    const element = document.getElementById("albums-pagination");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  function handleSelectAlbum(id) {
    if (selectedAlbums.length <= itemsPerPage) {
      let selectedAlbumsCopy = [...selectedAlbums];
      const newArray = selectedAlbumsCopy.includes(id)
        ? selectedAlbumsCopy.filter((item) => item !== id)
        : [...selectedAlbumsCopy, id];
      setSelectedAlbums(newArray);
    }
  }

  const handleSelectAll = () => {
    setSelectAll((prev) => !prev);
    let newArray = [];

    if (!selectAll) {
      filteredUserAlbums?.slice(startIndex, endIndex)?.forEach((album) => {
        newArray?.push(album?.id);
      });

      setSelectedAlbums(newArray);
    } else {
      setSelectedAlbums([]);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (changes < 1) {
        setChanges((prev) => prev + 1);
      } else {
        setChanges(1);
        let termFilter = filtrarArray(userAlbums, searchTerm);
        setFilteredUserAlbums(termFilter);
      }
    }, 1000); // tiempo de espera de 500 ms antes de actualizar debouncedInputValue

    return () => {
      clearTimeout(timerId);
    };
  }, [
    searchTerm,
    changes,
    userAlbums,
    setFilteredUserAlbums,
    setCurrentPage,
    setChanges,
  ]);

  useEffect(() => {
    // Calcula el índice de inicio basado en la página actual y los elementos por página
    const startIndex = (currentPage - 1) * itemsPerPage;
    setStartIndex(startIndex);

    // Calcula el índice final sumando el índice de inicio y los elementos por página
    const endIndex = startIndex + itemsPerPage;
    setEndIndex(endIndex);
  }, [itemsPerPage, currentPage]);

  return (
    <Fragment>
      {session?.user && (
        <Box
          id="albums-pagination"
          sx={{
            flexGrow: 1,
            backgroundColor: "#121216",
            padding: "2rem",
            borderRadius: "2rem",
            width: "100% !important",
          }}
        >
          <AlbumsFilter
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            handleChange={handleChange}
            count={Math.round(filteredUserAlbums?.length / itemsPerPage)}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            selectedAlbums={selectedAlbums}
            setSelectedAlbums={setSelectedAlbums}
            setSelectAll={setSelectAll}
            selectedActions={actions}
            showAlbumId={showAlbumId}
            setShowAlbumId={setShowAlbumId}
            loadAllPages={loadAllPages}
          />
          {filteredUserAlbums?.length ? (
            <TableContainer
              component={Paper}
              sx={{
                bgcolor: "transparent !important",
                backgroundImage: "none !important",
                width: "100%",
              }}
            >
              <Table
                sx={{
                  width: "100%",
                  opacity: "1",
                }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        color="default"
                        checked={selectAll}
                        onChange={(e) => {
                          handleSelectAll();
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{ color: "#dcdcdc", fontWeight: "bold" }}
                      >
                        {t("albums.name")}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body1"
                        sx={{ color: "#dcdcdc", fontWeight: "bold" }}
                      >
                        {t("albums.photos")}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        sx={{ color: "#dcdcdc", fontWeight: "bold" }}
                      >
                        {t("albums.status")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{ color: "#dcdcdc", fontWeight: "bold" }}
                      >
                        {searchTerm.length
                          ? t("albums.matches")
                          : t("albums.topKeywords")}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body1"
                        sx={{ color: "#dcdcdc", fontWeight: "bold" }}
                      >
                        {t("albums.created")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{ color: "#dcdcdc", fontWeight: "bold" }}
                      >
                        {t("albums.actions")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    all: !filteredUserAlbums?.length && "unset !important",
                    width: "100% !important",
                  }}
                >
                  {filteredUserAlbums
                    ?.slice(startIndex, endIndex)
                    ?.map((album, index) => (
                      <AlbumRow
                        album={album}
                        index={index}
                        searchTerm={searchTerm}
                        navigate={navigate}
                        setSearchTerm={setSearchTerm}
                        actions={actions}
                        selectedAlbums={selectedAlbums}
                        handleSelectAlbum={handleSelectAlbum}
                        key={index}
                        showAlbumId={showAlbumId}
                        blobImage={blobImages?.[album?.id]}
                        itemsPerPage={itemsPerPage}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box
              sx={{
                width: "100% !important",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                mt: 4,
                gap: "1rem",
              }}
            >
              {albumsLoading ? (
                <ClipLoader
                  color={"white"}
                  loading={true}
                  size={50}
                  css={{
                    display: "block",
                    margin: "0 auto",
                    borderColor: "red",
                  }}
                />
              ) : (
                <>
                  <img
                    src={"./assets/not-found-photos.png"}
                    alt={t("albums.noAlbums")}
                    style={{
                      width: "20rem",
                      height: "auto",
                      userSelect: "none",
                      pointerEvents: "none",
                    }}
                  />
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{ color: "#dcdcdc", fontWeight: "bold" }}
                  >
                    {t("albums.noneFound")}{" "}
                    <Link
                      onClick={() => navigate("/upload")}
                      sx={{ "&:hover": { cursor: "pointer" } }}
                    >
                      + {t("albums.createNew")}
                    </Link>
                  </Typography>
                </>
              )}
            </Box>
          )}
          <Pagination
            count={Math.round(filteredUserAlbums?.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "right",
            }}
          />
        </Box>
      )}
    </Fragment>
  );
};

export default AlbumsTable;
