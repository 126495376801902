import React, { Fragment, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { SessionContext } from "../context/SessionContext";
import { useContext } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import { NavbarContext } from "../context/NavbarContext";

function ConfigAPI({ supabase }) {
  const { t } = useTranslation();
  const session = useContext(SessionContext);
  const navbar = useContext(NavbarContext);

  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [tokenExists, setTokenExists] = useState(false);
  const [tokenCopied, setTokenCopied] = useState(false);
  const [tokenLoading, setTokenLoading] = useState(true);

  const fetchWithTimeout = async (url, options, timeout) => {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    try {
      const response = await fetch(url, {
        ...options,
        signal: controller.signal,
      });
      clearTimeout(id);
      return response;
    } catch (error) {
      clearTimeout(id);
      throw error;
    }
  };

  const generateToken = async () => {
    setLoading(true);
    try {
      const {
        data: { session: supabaseSession },
      } = await supabase.auth.getSession();

      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URL}/api/new-token`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${supabaseSession.access_token}`,
            "Content-Type": "application/json",
          },
        },
        15000
      );
      const json = await response.json();

      const { data: token, error } = json;
      if (!error) {
        setToken(token);
      } else {
        setToken("ERROR CREATING TOKEN");
      }
    } catch (error) {
      setToken("ERROR GENERATING TOKEN");
    }
    setLoading(false);
  };

  const checkToken = async () => {
    try {
      const {
        data: { session: supabaseSession },
      } = await supabase.auth.getSession();

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/supabase/tokens`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${supabaseSession.access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const { data, error } = await response.json();

      if (!error && data.length > 0) {
        setTokenExists(true);
      } else {
        setTokenExists(false);
      }
    } catch (e) {}

    setTokenLoading(false);
  };

  const copyToClipboard = () => {
    if (token) {
      navigator.clipboard.writeText(token);
      setTokenCopied(true);
    }
  };

  const promptPurchase = () => {
    navbar.setSelectedNavItem("more-photos-blocked");
    navbar.setOpenNavModal(true);
  };

  useEffect(() => {
    if (session?.user?.id) {
      checkToken();
    } else {
      setTokenLoading(false);
    }
  }, [session?.user?.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>ShopTag.ai - {t("apiHelmet.headingSubtext")}</title>
        <meta name="description" content={t("apiHelmet.headingDescription")} />
        <meta
          property="og:title"
          content={"ShopTag.ai - " + t("apiHelmet.headingSubtext")}
        />
        <meta
          property="og:description"
          content={t("apiHelmet.headingDescription")}
        />
        <meta
          property="og:image"
          content="https://app.shoptag.ai/storage/v1/object/public/assets/thumbnail.jpg"
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="ShopTag.ai" />
        <meta property="og:type" content="website" />
        <meta
          name="twitter:title"
          content={"ShopTag.ai - " + t("apiHelmet.headingSubtext")}
        />
        <meta
          name="twitter:description"
          content={t("apiHelmet.headingDescription")}
        />
        <meta
          name="twitter:image"
          content="https://app.shoptag.ai/storage/v1/object/public/assets/thumbnail.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${window.location.href}"
              },
              "headline": "ShopTag.ai - ${t("apiHelmet.headingSubtext")}",
              "description": "${t("apiHelmet.headingDescription")}",
              "image": "https://app.shoptag.ai/storage/v1/object/public/assets/thumbnail.jpg",
              "publisher": {
                "@type": "Organization",
                "name": "ShopTag.ai",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://shoptag.ai/assets/logo.png"
                }
              }
            }
          `}
        </script>
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <Box
        sx={{
          backgroundColor: "white",
          backgroundSize: "cover",
          backgroundPosition: "right",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          paddingTop: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: { xs: "75vw", md: "50vw" },
            mt: "8rem",
            mb: "8rem",
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            sx={{ fontWeight: "bold", textAlign: "center" }}
          >
            {t("api.header")}
          </Typography>

          <Typography paragraph textAlign="center">
            {t("api.overview")}
          </Typography>

          {loading || tokenLoading ? (
            <CircularProgress />
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center">
              {session?.user?.id ? (
                <Fragment>
                  {token ? (
                    <>
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography paragraph>{t("api.token")}</Typography>
                        <Tooltip
                          title={
                            tokenCopied
                              ? t("api.copyTooltipCompleted")
                              : t("api.copyTooltip")
                          }
                        >
                          <Typography
                            paragraph
                            sx={{
                              fontWeight: "bold",
                              "&:hover": {
                                textDecoration: "underline",
                                cursor: "pointer",
                              },
                            }}
                            onClick={copyToClipboard}
                          >
                            {token}
                          </Typography>
                        </Tooltip>
                      </Box>
                      <Typography
                        paragraph
                        textAlign="center"
                        sx={{ fontStyle: "italic", mb: "1.5rem" }}
                      >
                        {t("api.subtext")}
                      </Typography>
                    </>
                  ) : tokenExists ? (
                    <Typography paragraph>
                      {t("api.token")} •••••••••••••
                    </Typography>
                  ) : (
                    <Typography paragraph sx={{ fontWeight: "bold" }}>
                      {t("api.noToken")}
                    </Typography>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={
                      session?.enableExport ? generateToken : promptPurchase
                    }
                  >
                    {tokenExists || token
                      ? t("api.createNew")
                      : t("api.create")}
                  </Button>
                </Fragment>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (session?.stashedUser) {
                      session?.loadUserFromStash("/api");
                    } else {
                      navbar?.setIsVisible(true);
                      navbar?.setSelectedNavItem("login");
                      navbar?.setOpenNavModal(true);
                    }
                  }}
                >
                  {t("login.continue")}
                </Button>
              )}
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              marginTop: "2rem",
              padding: "2rem",
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              maxWidth: "100%",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {t("api.documentation")}
            </Typography>
            <Typography paragraph>{t("api.docSubtext1")}</Typography>

            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.endpoint")}
            </Typography>
            <Typography paragraph component="div" style={{ overflowX: "auto" }}>
              <code>POST https://server.shoptag.ai/api/keywords</code>
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.requestDetails")}
            </Typography>
            <Typography
              paragraph
              sx={{
                display: "inline",
              }}
            >
              {t("api.docSubtext2")}
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.authorization")}
            </Typography>
            <Typography paragraph component="div" style={{ overflowX: "auto" }}>
              {t("api.authorizationSubtext")}
              <pre>
                {`{
  "Authorization": "Bearer YOUR_API_TOKEN"
}`}
              </pre>
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.arguments")}
            </Typography>
            <Typography paragraph component="div">
              {t("api.argumentsSubtext")}
              <ul>
                <li>
                  <strong>file (required)</strong>: Image file (
                  {t("api.imageDes")}).
                </li>
                <li>
                  <strong>language (optional, default: en)</strong>: String (
                  {t("api.langDes")}).
                </li>
                <li>
                  <strong>minKeywords (optional, range: 5-40)</strong>: Integer
                  ({t("api.minDes")}).
                </li>
                <li>
                  <strong>maxKeywords (optional, range: 5-200)</strong>: Integer
                  ({t("api.maxDes")}).
                </li>
                <li>
                  <strong>requiredKeywords (optional, comma-separated) </strong>
                  : String ({t("api.reqDes")}
                  ).
                </li>
                <li>
                  <strong>excludedKeywords (optional, comma-separated)</strong>:
                  String ({t("api.excludedDes")}).
                </li>
                <li>
                  <strong>customContext (optional) </strong>: String (
                  {t("api.contextDes")}).
                </li>
                <li>
                  <strong>prohibitedCharacters (optional) </strong>: String (
                  {t("api.prohibitedCharsDes")}).
                </li>
                <li>
                  <strong>
                    minDescriptionCharacters (optional, range: 5-200)
                  </strong>
                  : Integer ({t("api.minDesDes")}).
                </li>
                <li>
                  <strong>
                    maxDescriptionCharacters (optional, range: 50-500)
                  </strong>
                  : Integer ({t("api.maxDesDes")}).
                </li>
                <li>
                  <strong>minTitleCharacters (optional, range: 5-200)</strong>:
                  Integer ({t("api.minTitleDes")}).
                </li>
                <li>
                  <strong>maxTitleCharacters (optional, range: 50-500)</strong>:
                  Integer ({t("api.maxTitleDes")}).
                </li>
                <li>
                  <strong>useFileNameForContext (optional)</strong>: Boolean (
                  {t("api.fileNameContextDes")}).
                </li>
                <li>
                  <strong>singleWordKeywordsOnly (optional)</strong>: Boolean (
                  {t("api.singleWordDes")}).
                </li>
              </ul>
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.response")}
            </Typography>
            <Typography paragraph>
              {t("api.responseSubtext")}
              <ul>
                <li>
                  <strong>title</strong>: String ({t("api.titleDes")}).
                </li>
                <li>
                  <strong>description</strong>: String ({t("api.desDes")}).
                </li>
                <li>
                  <strong>keywords</strong>: String Array (
                  {t("api.keywordsDes")}).
                </li>
              </ul>
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.exampleResponse")}
            </Typography>
            <Typography paragraph component="div" style={{ overflowX: "auto" }}>
              <pre>
                {`{
  "error": null,
  "data": {
    "title": "Teal Blue Weighted Blanket",
    "description": "This teal blue weighted blanket is perfect for sleeping.",
    "keywords": ["blanket", "weighted", "teal", "sleep", "cotton"]
  }
}`}
              </pre>
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.exampleResponseFail")}
            </Typography>
            <Typography paragraph component="div" style={{ overflowX: "auto" }}>
              <pre>
                {`{
  "error": "There was no file uploaded",
  "data": null
}`}
              </pre>
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.example")} (CURL)
            </Typography>
            <Typography paragraph component="div" style={{ overflowX: "auto" }}>
              <pre>
                {`curl -X POST https://server.shoptag.ai/api/keywords \\
  -H 'Authorization: Bearer YOUR_API_TOKEN' \\
  -F 'file=@path_to_image.jpg' \\
  -F 'language=en' \\
  -F 'maxKeywords=5' \\
  -F 'requiredKeywords=teal,cotton' \\
  -F 'customContext=weighted blanket'`}
              </pre>
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.example")} (Python)
            </Typography>
            <Typography paragraph component="div" style={{ overflowX: "auto" }}>
              <pre>
                {`import requests

url = "https://server.shoptag.ai/api/keywords"

headers = {
  "Authorization": f'Bearer YOUR_API_TOKEN'
}

payload = {
  "language": "en",
  "maxKeywords": 5,
  "requiredKeywords": "teal,cotton",
  "customContext": "weighted blanket"
}

files = [
  ('file', open('path_to_image.jpg','rb'))
]

response = requests.request("POST",
                            url,
                            headers=headers,
                            data=payload,
                            files=files)`}
              </pre>
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.example")} (PHP)
            </Typography>
            <Typography paragraph component="div" style={{ overflowX: "auto" }}>
              <pre>
                {`<?php

$url = "https://server.shoptag.ai/api/keywords";

$headers = array(
    "Authorization: Bearer YOUR_API_TOKEN",
);

$postFields = array(
    "language" => "en",
    "maxKeywords" => 5,
    "requiredKeywords" => "teal,cotton",
    "customContext" => "weighted blanket",
    "file" => new CURLFile('path_to_image.jpg')
);

$curl = curl_init();

curl_setopt($curl, CURLOPT_URL, $url);
curl_setopt($curl, CURLOPT_RETURNTRANSFER, true);
curl_setopt($curl, CURLOPT_POST, true);
curl_setopt($curl, CURLOPT_POSTFIELDS, $postFields);
curl_setopt($curl, CURLOPT_HTTPHEADER, $headers);

$response = curl_exec($curl);

if (curl_errno($curl)) {
    echo 'Error:' . curl_error($curl);
}

curl_close($curl);

echo $response;

?>`}
              </pre>
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.example")} (Node.js)
            </Typography>
            <Typography paragraph component="div" style={{ overflowX: "auto" }}>
              <pre>
                {`const axios = require('axios');
const FormData = require('form-data');
const fs = require('fs');

const url = "https://server.shoptag.ai/api/keywords";

const headers = {
    "Authorization": 'Bearer YOUR_API_TOKEN',
};

const form = new FormData();
form.append('language', 'en');
form.append('maxKeywords', 5);
form.append('requiredKeywords', 'teal,cotton');
form.append('customContext', 'weighted blanket');
form.append('file', fs.createReadStream('path_to_image.jpg'));

axios.post(url, form, { headers: {...form.getHeaders(), ...headers} })
.then(response => {
    console.log(response.data);
})
.catch(error => {
    console.error(error);
});`}
              </pre>
            </Typography>

            <Typography
              paragraph
              sx={{
                fontWeight: "bold",
              }}
            >
              {t("api.reminder")}
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              FAQ
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.question1")}
            </Typography>
            <Typography paragraph>{t("api.answer1")}</Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.question2")}
            </Typography>
            <Typography paragraph>{t("api.answer2")}</Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.question3")}
            </Typography>
            <Typography paragraph>{t("api.answer3")}</Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.question4")}
            </Typography>
            <Typography paragraph>{t("api.answer4")}</Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.question5")}
            </Typography>
            <Typography paragraph>{t("api.answer5")}</Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.question6")}
            </Typography>
            <Typography paragraph>{t("api.answer6")}</Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.question7")}
            </Typography>
            <Typography paragraph>{t("api.answer7")}</Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.question8")}
            </Typography>
            <Typography paragraph>{t("api.answer8")}</Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.question9")}
            </Typography>
            <Typography paragraph>{t("api.answer9")}</Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.question10")}
            </Typography>
            <Typography paragraph>{t("api.answer10")}</Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.question11")}
            </Typography>
            <Typography paragraph>{t("api.answer11")}</Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.question12")}
            </Typography>
            <Typography paragraph>{t("api.answer12")}</Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("api.question13")}
            </Typography>
            <Typography paragraph>{t("api.answer13")}</Typography>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
}

export default ConfigAPI;
