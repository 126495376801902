import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ModalItem from "./modalItem";
import TabSelector from "./TabSelector";
import { Fragment, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { addCommas } from "../utils";
import { NavbarContext } from "../context/NavbarContext";

const MyAccount = ({
  supabase,
  session,
  handleClose,
  handleOpen,
  defaultCategory,
}) => {
  const { t } = useTranslation();
  const navbar = useContext(NavbarContext);
  const [category, setCategory] = useState(defaultCategory || 0);
  const [albumDeleteLoading, setAlbumDeleteLoading] = useState(false);

  const getAllAlbumIds = async () => {
    try {
      const {
        data: { session: supabaseSession },
      } = await supabase.auth.getSession();

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/supabase/album-ids`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${supabaseSession.access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const { data, error } = await response.json();

      if (error) {
        return [];
      } else {
        return data.map((album) => album.id);
      }
    } catch (e) {
      return [];
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "center",
        width: { xs: "100%", lg: "25rem" },
        height: "45rem",
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          mb: "1rem",
        }}
      >
        <Typography variant="h5" color="initial" fontWeight="bold">
          {t("account.account")}
        </Typography>
        <TabSelector
          options={[
            { name: t("account.general") },
            { name: t("account.usage") },
          ]}
          selectedTab={category}
          setSelectedTab={setCategory}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          paddingBottom: "2rem",
        }}
      >
        {category === 0 && (
          <Fragment>
            <ModalItem
              title={t("account.account")}
              primaryContent={"👤 " + session?.user?.email}
            >
              <Button
                variant="contained"
                color="info"
                onClick={() => {
                  handleClose();
                  session.logout();
                }}
              >
                {t("account.logout")}
              </Button>
            </ModalItem>
            <ModalItem
              title={t("account.affiliates")}
              primaryContent={"💸 " + t("account.affiliatesSubtext")}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  window.open(process.env.REACT_APP_AFFILIATE_URL, "_blank");
                }}
              >
                {t("account.affiliatesButton")}
              </Button>
            </ModalItem>
            <ModalItem
              title={t("change.changePassword")}
              primaryContent={"🔑 " + t("change.changePasswordSubtext")}
            >
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  handleOpen("change-password");
                }}
              >
                {t("change.changePasswordButton")}
              </Button>
            </ModalItem>
            {session?.bundleCreditsAllowedTotal > 3 ? (
              <ModalItem
                title={t("account.deleteAllAlbums")}
                primaryContent={"🗑️ " + t("account.deleteAllAlbumsSubtext")}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    if (albumDeleteLoading) return;
                    setAlbumDeleteLoading(true);
                    getAllAlbumIds().then((ids) => {
                      setAlbumDeleteLoading(false);
                      if (ids.length > 0) {
                        navbar.setOpenNavModal(ids);
                        navbar.setSelectedNavItem("album-delete");
                      }
                    });
                  }}
                >
                  {t("account.deleteAllAlbumsButton")}
                </Button>
              </ModalItem>
            ) : (
              <ModalItem
                title={t("account.deleteAccount")}
                primaryContent={"🚫 " + t("account.deleteAccountSubtext")}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    if (albumDeleteLoading) return;
                    setAlbumDeleteLoading(true);
                    getAllAlbumIds().then((ids) => {
                      setAlbumDeleteLoading(false);
                      if (ids.length === 0) {
                        handleClose();
                        session.logout();
                      } else {
                        navbar.setOpenNavModal(ids);
                        navbar.setSelectedNavItem("album-delete-logout");
                      }
                    });
                  }}
                >
                  {t("account.deleteAccountButton")}
                </Button>
              </ModalItem>
            )}
          </Fragment>
        )}
        {category === 1 && (
          <Fragment>
            {session?.bundleCreditsAllowed === undefined ||
            session?.bundleCreditsUsed === undefined ? (
              <ModalItem
                title={t("account.bundleUsage")}
                primaryContent={t("account.usageError")}
              />
            ) : (
              <ModalItem
                title={t("account.bundleUsage")}
                primaryContent={`💎 ${addCommas(
                  Math.max(
                    0,
                    session?.bundleCreditsAllowed -
                      (session?.bundleCreditsUsed || 0)
                  )
                )} ${t("account.photosRemaining")}`}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleOpen("more-photos");
                  }}
                >
                  {t("account.subscribeButton")}
                </Button>
              </ModalItem>
            )}
            <ModalItem
              title={t("invoices.viewInvoices")}
              primaryContent={"💰 " + t("invoices.viewInvoicesSubtext")}
            >
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  window.open(
                    `https://zenvoice.io/p/671147ba1ee57001b87a2c3c?email=${encodeURIComponent(
                      session?.user?.email || ""
                    )}`,
                    "_blank"
                  );
                }}
              >
                {t("invoices.viewInvoicesButton")}
              </Button>
            </ModalItem>
            <ModalItem
              title={t("account.billing")}
              primaryContent={"💳 " + t("account.managePayments")}
            >
              <Button
                variant="contained"
                color="info"
                onClick={() => {
                  window.open(process.env.REACT_APP_STRIPE_URL, "_blank");
                }}
              >
                {t("account.manage")}
              </Button>
            </ModalItem>
          </Fragment>
        )}
      </Box>
    </Box>
  );
};
export default MyAccount;
